<form [formGroup]="form">
  <!-- Project Duration -->
  <mat-expansion-panel
    [expanded]="expanded"
    data-testid="project-duration-panel"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="project-duration-panel-title">
        {{ 'Scenario.labels.wizard.time.headers.projectDuration' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_time.wizard_time_project_duration'"
          data-testid="project-duration-panel-tooltip"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="prosumer-slider-container" fxLayout="column" fxLayoutGap="10px">
      <ngx-slider
        #durationSlider
        [(value)]="projectDurationStartValue"
        [(highValue)]="projectDurationEndValue"
        [options]="sliderOptions"
        [ngClass]="{ 'disabled-slider': sliderDisabled$ | async }"
        (userChangeEnd)="onDurationChange($event)"
        data-testid="project-duration-slider"
      ></ngx-slider>
      <p class="mat-caption" data-testid="project-duartion-duration">
        Duration: {{ duration$ | async }}
      </p>
    </div>
  </mat-expansion-panel>
  <!-- Years To Optimize -->
  <mat-expansion-panel
    [expanded]="expanded"
    data-testid="yearsToOptimize-panel"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="yearsToOptimize-title">
        {{ 'Scenario.labels.wizard.time.headers.yearsToOptimize' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_time.wizard_time_years_to_optimize'"
          data-testid="yearsToOptimize-tooltip"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutGap="10px">
      <div *ngIf="!isViewMode()" class="sub-header">
        <!-- warning message if user is expert and limit has been reached -->
        <div
          *ngIf="isClientExpert$ | async; else isNotExpert"
          class="mat-caption"
        >
          <i
            *ngIf="hasReachedLimit()"
            [innerHTML]="
              'Scenario.messages.yearsToOptimize.quantityWarning' | translate
            "
            data-testid="yearsToOptimize-expert-warning"
          ></i>
        </div>
        <ng-template #isNotExpert>
          <!-- If user still has allowable years selection -->
          <div
            class="mat-caption"
            *ngIf="!hasReachedLimit(); else maximumReached"
            [innerHTML]="
              ('Scenario.messages.yearsToOptimize.selectYears' | translate) +
              availableYears()
            "
            data-testid="yearsToOptimize-remaining-selectable"
          ></div>
          <!-- Else, print this template -->
          <ng-template #maximumReached>
            <div class="mat-caption">
              <i
                [innerHTML]="
                  'Scenario.messages.yearsToOptimize.maximumReached' | translate
                "
                data-testid="yearsToOptimize-maximumReached"
              ></i>
            </div>
          </ng-template>
        </ng-template>
        <mat-spinner
          *ngIf="loadingResponse$ | async"
          data-testid="spinner-yearsToOptimize"
          [color]="spinnerColorYearsToOptimize"
          [diameter]="spinnerSizeYearsToOptimize"
        >
        </mat-spinner>
      </div>
      <mat-button-toggle-group
        *mcRerender="ytoRefreshSeed"
        [hideMultipleSelectionIndicator]="true"
        [multiple]="true"
        [disabled]="
          mode === 'read' || form?.disabled || (loadingResponse$ | async)
        "
        data-testid="years-to-optimize-group"
      >
        <div fxLayout="row wrap">
          <mat-button-toggle
            *ngFor="let year of durationYears$ | async"
            class="toggle-year-button"
            fxLayoutAlign="center center"
            [value]="year"
            [checked]="isYearSelected(year)"
            [disabled]="
              (!isYearSelected(year) &&
                hasReachedLimit() &&
                (isClientExpert$ | async) !== true) ||
              mode === 'read' ||
              form?.disabled
            "
            (change)="onYearToggle($event)"
            [attr.data-testid]="'yearsToOptimize-button-' + year"
          >
            {{ year }}
          </mat-button-toggle>
        </div>
      </mat-button-toggle-group>
      <div
        class="mat-error eyes-error"
        [ngStyle]="{ display: noSelectedYears ? 'block' : 'none' }"
        [innerHTML]="'Scenario.messages.yearsToOptimize.invalid' | translate"
        data-testid="yearsToOptimize-error"
      ></div>
    </div>
  </mat-expansion-panel>
  <!-- Time Horizon Periods -->
  <mat-expansion-panel [expanded]="expanded" data-testid="timeHorizon-panel">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="timeHorizon-title">
        {{ 'Scenario.labels.wizard.time.headers.timeHorizon' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_time.wizard_time_time_horizon_section'"
          data-testid="timeHorizon-tooltip"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutGap="5px">
      <!-- Period Type Options -->
      <div class="radio-container">
        <mat-radio-group
          scenarioUpdater
          fxLayoutGap="15px"
          key="fullTimeHorizon"
          formControlName="timeHorizon"
          [view]="view"
          [nextValue]="isHorizonSelected$ | async"
          (change)="onHorizonChange($event)"
          (statusChange)="onStatusChange($event)"
          data-testid="radio-group-time-horizon-type"
        >
          <mat-radio-button
            value="full"
            [prosumerTooltip]="'wizard_time.wizard_time_full_year_time_horizon'"
            [disabled]="mode === 'read' || form?.disabled"
            data-testid="timeHorizon-radio-fullYear"
            >Full Year Time Horizon</mat-radio-button
          >
          <mat-radio-button
            value="periods"
            [prosumerTooltip]="'wizard_time.wizard_time_periods'"
            [disabled]="mode === 'read' || form?.disabled"
            data-testid="timeHorizon-radio-periods"
          >
            Periods</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <!-- Periods Table -->
      <div *ngIf="(isHorizonSelected$ | async) !== true">
        <prosumer-sparkline-table
          prosumerCommentParentRefresh
          parentType="detail"
          detailType="timePeriod"
          [detailStore]="timePeriodInfo.store"
          [commentable]="true"
          idField="periodId"
          [columnsDef]="periodsColumnsDef"
          [data]="timeHorizonPeriods$ | async"
          [searchInput]="true"
          [searchPredicate]="periodsSearchPredicate"
          [loading]="loading"
          [saving]="saving"
          [listItemLoading]="listItemLoading$ | async"
          (add)="addPeriod$.emit()"
          (edit)="editPeriod$.emit($event)"
          (delete)="deletePeriod$.emit($event)"
          data-testid="timeHorizon-sparkline"
        >
        </prosumer-sparkline-table>
      </div>
    </div>
  </mat-expansion-panel>

  <prosumer-stepper-nav
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
    (next)="onClickNext()"
    data-testid="time-stepper-nav"
  >
  </prosumer-stepper-nav>
</form>
